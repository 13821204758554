import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x.svg';
import Arrow from 'public/assets/icons/chevron-right-long.svg';
import { Item, Offer } from '@/api';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import SmallLogo from '@/components/general/LogoSmall';
import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import { useMutation } from '@tanstack/react-query';
import { counterOfferFn, submitOfferFn } from '@/queries/offer';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import toast from 'react-hot-toast';
import BidTimer from '@/components/general/BidTimer';
import i18next from 'i18next';
import useOffers from '@/hooks/useOffers';
import dynamic from 'next/dynamic';
import THDialogWrapper from '../../../general/THDialogWrapper';
import Button from '../../../general/Button';
import Price from '../../../general/Price';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

type ProductOfferProps = {
    onHide: () => void;
    show: boolean;
    offer: Offer | undefined | null;
    isCounter?: boolean;
    product: Item | undefined;
};

const ProductOfferModal = (props: ProductOfferProps) => {
    const { onHide, product, show, isCounter, offer } = props;
    const [customBidValue, setCustomBidValue] = useState<number>((isCounter && offer) ? Number(offer.current_amount) + 1 : Number(product?.min_offer_amount) || 0);
    const [success, setSuccess] = useState<boolean>(false);
    const [successAnimation, setSuccessAnimation] = useState<object>();
    const [successTitle, setSuccessTitle] = useState<string>('');
    const [successContent, setSuccessContent] = useState<string>('');
    const [offerString, setOfferString] = useState<string>(`$${((isCounter && offer) ? Number(offer.current_amount) + 1 : Number(product?.min_offer_amount)).toFixed()}`);
    const { invalidateQueries } = useOffers(product, false, false);

    const isInstant = product?.auto_accept_offer;

    useEffect(() => {
        if (offer) {
            setOfferString(`$${(Number(offer?.current_amount) + 1).toFixed()}`);
        }
    }, [offer]);

    const reset = () => {
        setTimeout(() => {
            setSuccess(false);
            setSuccessTitle('');
            setSuccessContent('');
        }, 350);
    };

    useEffect(() => {
        reset();
        return reset;
    }, []);

    const makeOfferMutation = useMutation({
        mutationFn: submitOfferFn,
        onSuccess: async (response) => {
            setSuccess(true);
            invalidateQueries();
            if (response) {
                switch (response.status) {
                    case Offer.StatusEnum.CounterAccepted:
                    case Offer.StatusEnum.Accepted:
                        setSuccessTitle(i18next.t('common:offers.offer-accepted'));
                        setSuccessContent(i18next.t('common:offers.offer-accepted-text'));
                        setSuccessAnimation(await import('public/assets/animations/success.json'));
                        break;
                    case Offer.StatusEnum.Rejected:
                    case Offer.StatusEnum.CounterRejected:
                        setSuccessTitle(i18next.t('common:offers.offer-rejected'));
                        setSuccessContent(i18next.t('common:offers.offer-rejected-text'));
                        setSuccessAnimation(await import('public/assets/animations/alert.json'));
                        break;
                    case Offer.StatusEnum.Pending:
                        setSuccessTitle(i18next.t('common:offers.offer-pending'));
                        setSuccessContent(i18next.t('common:offers.offer-pending-text'));
                        setSuccessAnimation(await import('public/assets/animations/warning.json'));
                        break;
                    default:
                        break;
                }
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            toast.error(transformedError.message);
        },

    });

    const makeCounterOfferMutation = useMutation({
        mutationFn: counterOfferFn,
        onSuccess: async (response) => {
            invalidateQueries();
            setSuccess(true);
            if (response) {
                switch (response.status) {
                    case Offer.StatusEnum.Countered:
                        setSuccessTitle(i18next.t('common:offers.counter-offer-submitted'));
                        setSuccessContent(i18next.t('common:offers.counter-offer-submitted-text'));
                        setSuccessAnimation(await import('public/assets/animations/success.json'));
                        break;
                    default:
                        break;
                }
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            toast.error(transformedError.message);
        },

    });

    const offerTooLow = useMemo(() => {
        if (isCounter) {
            return customBidValue && product && (Number(customBidValue) < Number(offer?.current_amount) + 1);
        }
        return customBidValue && product && (Number(customBidValue) < Number(product?.min_offer_amount) || (isCounter && Number(customBidValue) <= Number(product?.min_offer_amount)));
    }, [offer?.current_amount, customBidValue, isCounter, product]);

    return (
        <Transition.Root show={show} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-1000"
                onClose={() => {
                    onHide();
                    reset();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity z-[100]" />
                </Transition.Child>
                <div className="fixed z-[100] overflow-y-auto right-[0px] lg:right-[30px] lg:top-[0px] top-[0px] bottom-[0px] lg:bottom-[0px] left-[0px] lg:left-[50%]">
                    <div className="flex min-h-full items-end justify-end p-4 sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative  transform  rounded-[8px] bg-white shadow-xl transition-all w-full max-w-[100vw] lg:max-w-[40vw] lg:h-[94vh] h-[100vh] ">
                                <>
                                    <button
                                        className="cursor-pointer"
                                        onClick={() => {
                                            reset();
                                            onHide();
                                            setCustomBidValue(Number(Number(product?.min_offer_amount).toFixed(0)));
                                        }}><XIcon className="absolute right-[15px] top-[26px] scale-[0.8]" />
                                    </button>
                                    {success &&
                                        <button
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setSuccess(false);
                                            }}><Arrow className="absolute left-[15px] top-[28px] scale-[1.5] rotate-180" />
                                        </button>
                                    }
                                    <div className="text-center border-b-[1px] border-grey13">
                                        <h3 className="text-[20px] font-[500] pb-[22px]">{isCounter ? i18next.t('common:offers.counter-offer') : isInstant ? i18next.t('common:offers.make-offer-instant') : i18next.t('common:offers.make-offer')}</h3>
                                    </div>
                                    <AnimatePresence mode="popLayout" />
                                    {!success &&
                                        <div className="space-y-[20px] p-[40px] flex flex-col justify-between h-[93%]">
                                            <div className="gap-[32px] flex flex-col">
                                                <div>
                                                    <span className="text-[24px] font-[500] block">{isCounter ? i18next.t('common:offers.set-counter-price') : isInstant ? i18next.t('common:offers.submit-instant-buy-offer') : i18next.t('common:offers.set-offer-price')}:</span>
                                                    <span className="text-[18px] font-[400] block text-grey15 mt-[8px]">{isCounter ? i18next.t('common:offers.you-can-counter') : isInstant ? i18next.t('common:offers.send-an-offer-to-seller-instant') : i18next.t('common:offers.send-an-offer-to-seller')}</span>
                                                </div>

                                                <div className="flex flex-row items-center gap-[16px]">
                                                    {(!product?.images || !product?.images[0].url) ?
                                                        <SmallLogo className="cursor-pointer lg-tablet:h-[80px] h-[80px] w-[80] object-cover lg-tablet:w-[80px] lg-tablet:max-h-[80px] lg-tablet:min-w-[80px] block p-12 [&>path]:fill-blue2" /> :
                                                        <ResponsiveImage src={product?.images[0].url} imageWidth={80} width={80} height={80} className="cursor-pointer lg-tablet:h-[80px] h-[120px] object-cover lg-tablet:w-[80px] lg-tablet:max-h-[80px] w-full lg-tablet:min-w-[80px] block rounded-[8px]" />}
                                                    <div>
                                                        <h3 className="text-[16px] font-[500]">{product?.name}</h3>
                                                        {product?.artists && product.artists.length > 0 && <span className="text-[14px] font-[500] text-grey15">{product?.artists[0].name}</span>}
                                                    </div>
                                                </div>

                                                {!isCounter &&
                                                    <div className="mx-auto bg-grey16 rounded-[10px] p-[14px] w-full">
                                                        <span className="text-[12px] weight-[500] text-grey15">{i18next.t('common:offers.minimum-offer')}</span><Price className="text-[18px] font-[600]" justification="L" number={product?.min_offer_amount ?? '0'} />
                                                    </div>
                                                }

                                                {isCounter &&
                                                    <div className="flex justify-between flex-row w-full">
                                                        <div><span className="block text-[12px] text-grey15 font-[500]">{i18next.t('common:offers.offer')}</span><span className="block"><Price className="text-[18px] font-[600]" justification="L" number={offer?.current_amount ?? '0'} /></span></div>
                                                        <div><span className="block text-[12px] text-grey15 font-[500]">{i18next.t('common:offers.offer-expires')}</span><span className="block text-[18px] font-[600]"><BidTimer dueDate={new Date(offer?.expires_at ?? '')} /></span></div>
                                                    </div>
                                                }
                                            </div>

                                            <div>
                                                <div className="flex justify-center">
                                                    <div className="flex-1">
                                                        <div className={clsx('relative flex justify-between flex-col mb-4 mt-2 py-2 items-center')}>
                                                            <motion.span
                                                                animate={{ borderColor: offerTooLow ? '#D92D20' : '#D0D5DD', color: offerTooLow ? '#D92D20' : '#000000' }}
                                                                className={clsx(' text-[11px] font-[500] text-center block uppercase')}>
                                                                {offerTooLow ? `${isCounter ? i18next.t('common:offers.counter') : i18next.t('common:offers.offer')} ${i18next.t('common:offers.too-low')}` : `${isCounter ? i18next.t('common:offers.your-counter') : i18next.t('common:offers.your-offer')}`}
                                                            </motion.span>
                                                            <motion.input
                                                                animate={{ borderColor: offerTooLow ? '#D92D20' : '#D0D5DD', color: offerTooLow ? '#D92D20' : '#000000' }}
                                                                placeholder="$12,345"
                                                                style={{ borderBottom: '1px solid #D0D5DD' }}
                                                                readOnly={isInstant}
                                                                onChange={(e) => {
                                                                    setOfferString(typeof e.target.value === 'number' ? `$${Number(e.target.value).toFixed()}` : !e.target.value.includes('$') ? `$${e.target.value}` : `${e.target.value}`);
                                                                    setCustomBidValue(Number(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')));
                                                                }}
                                                                value={offerString ?? ''}
                                                                className={clsx(' w-full text-center text-[24px] font-[500] pb-[5px]')}
                                                                autoFocus
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {!isCounter && !isInstant &&
                                                    <div className="flex gap-4 mb-[16px]">
                                                        {product?.suggested_offer_amounts.map((bid) => {
                                                            return (
                                                                <Button
                                                                    key={bid}
                                                                    onClick={() => {
                                                                        setOfferString(`$${Number(bid).toFixed()}`);
                                                                        setCustomBidValue(Number(bid));
                                                                    }}
                                                                    fullWidth
                                                                    light>
                                                                    <Price className="@[600px]:text-[1.375rem] text-[1rem] font-[700]" justification="L" number={bid} />
                                                                </Button>
                                                            );
                                                        })}
                                                    </div>
                                                }
                                                <Button
                                                    onClick={() => {
                                                        if (isCounter) {
                                                            makeCounterOfferMutation.mutate({ itemId: offer?.id ?? '', dto: { amount: String(customBidValue.toFixed(2)) ?? 0 } });
                                                        } else {
                                                            makeOfferMutation.mutate({ itemId: product?.id ?? '', dto: { amount: String(customBidValue.toFixed(2)) ?? 0 } });
                                                        }
                                                    }}
                                                    loading={makeOfferMutation.isLoading || makeCounterOfferMutation.isLoading}
                                                    disabled={!customBidValue || offerTooLow === true}
                                                    fullWidth>{i18next.t('common:offers.submit')} {isCounter ? i18next.t('common:offers.counter') : isInstant ? i18next.t('common:offers.instant-buy-offer') : i18next.t('common:offers.offer')}
                                                </Button>
                                            </div>

                                        </div>
                                    }

                                    {success &&
                                        <motion.div
                                            transition={{ ease: 'easeOut' }}
                                            key="c"
                                            animate={{ x: 0 }}
                                            initial={{ x: '200%' }}
                                            exit={{ x: '-200%' }}
                                        >
                                            <div className="space-y-[20px] p-[40px] flex flex-col justify-between h-[93%]">
                                                <div className="h-full my-auto">
                                                    <div className="gap-[32px] flex flex-col items-center text-center max-w-[360px] w-full mx-auto">
                                                        <Lottie style={{ width: 150, height: 150 }} animationData={successAnimation} loop={false} />
                                                        <h3 className="text-[32px] text-[500] font-[500]">{successTitle}</h3>
                                                        <span className="text-[18px] text-[400] leading-[32px] text-grey15 tracking-[-0.2px]">{successContent}</span>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col gap-[16px]">
                                                    <Button
                                                        onClick={() => {
                                                            reset();
                                                            onHide();
                                                        }}
                                                        fullWidth>{i18next.t('common:offers.close')}
                                                    </Button>
                                                </div>

                                            </div>
                                        </motion.div>
                                    }

                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default ProductOfferModal;
