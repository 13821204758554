import { CheckIcon } from '@heroicons/react/20/solid';
import React from 'react';
import Checkbox from 'react-custom-checkbox';

export type ICheckboxProps = {
    label: string;
    value: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const THCheckbox = (props: ICheckboxProps) => {
    const { label, value, checked, onChange } = props;
    return (
        <Checkbox
            borderColor="#D0D5DD"
            borderWidth={1}
            style={{ cursor: 'pointer' }}
            className="[&>input]:hidden"
            labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 14, paddingLeft: 5 }}
            label={label}
            value={value}
            checked={checked}
            onChange={onChange}
            icon={
                <div
                    style={{
                        padding: 1,
                        width: '90%',
                        height: '90%',
                        background: 'linear-gradient(229deg, #158FD2 0%, #004E78 100%)',
                        borderRadius: 3
                    }}
                >
                    <CheckIcon className="text-white" />
                </div>
            }
        />
    );
};

export default THCheckbox;
